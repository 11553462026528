<template>
  <div>
    <training-dashboard
      v-if="isProgramTraining"
    />
    <mentorship-dashboard
      v-if="!isProgramTraining"
    />
  </div>
</template>

<script>
import { programTypes, userRoles } from '@models';
import MentorshipDashboard from './components/MentorshipDashboard.vue';
import TrainingDashboard from './components/TrainingDashboard.vue';
import { makeErrorToast } from "@/libs/utils";
import { mapGetters } from "vuex";

export default {
  name: 'Dashboard',
  components: {
    MentorshipDashboard,
    TrainingDashboard
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      if (vm.isProgramTraining) {
        return;
      }
      for (let r of vm.profile.roles) {
        if (r === userRoles.MENTOR || r === userRoles.MENTEE) {
          await vm.loadApplicationList(r);
        }  
      }

      if (
        !vm.applications || 
      vm.applications?.mentor?.completed_percent > 0 || 
      vm.applications?.mentee?.completed_percent > 0) {
        return;
      }
      if(vm.isUserMentor) {
        const questionId = [...vm.applicationMentor?.questions][0]?.id;
        return vm.$router.replace({ 
          name : 'participant-application-mentor', 
          params: { role: userRoles.MENTOR , questionId }, 
          query: {'enrolement' : true}
        });
      }

      if(vm.isUserMentee) {
        const questionId = [...vm.applicationMentee?.questions][0]?.id;
        return vm.$router.replace({ 
          name : 'participant-application-mentee', 
          params: { role: userRoles.MENTEE , questionId }, 
          query: {'enrolement' : true}
        });
      }
    });
  },
  computed: {
    ...mapGetters("participants", ['isApplicationAnswersLoading', 'applicationMentee', 'applicationMentor']),
    applications() {
      return this.$store.getters['participants/applications'];
    },
    isUserMentee() {
      return this.profile.roles.includes(userRoles.MENTEE); 
    },
    isUserMentor() {
      return this.profile.roles.includes(userRoles.MENTOR); 
    },
    profile() {
      return this.$store.getters['profile/profile'];
    },
    program() {
      return this.$store.getters['participants/program'];
    },
    isProgramTraining () {
      return this.program?.type_id === programTypes.TRAINING;
    },
    applicationSetId() {
      const program = this.$store.getters['participants/program'];
      if (program && program.application_set) {
        return program.application_set.id;
      }
      return undefined;
    },
  },
  methods: {
    async loadApplicationList(role) {
      if (!this.program) {return false;}
      try {
        let ownApplication = await this.$store.dispatch("participants/FETCH_OWN_APPLICATION_SETS", {
          programId: this.program.id,
          applicationSetId: this.applicationSetId,
          role: role
        });

        if (ownApplication) {
          await this.$store.dispatch("participants/FETCH_OWN_APPLICATION_ANSWERS", {
            programId: this.program.id,
            formId: ownApplication.id,
            userId: this.profile.id
          },
          true
          );
        }
      } catch (e) {
        this.$toast(makeErrorToast("Application list not loaded."));
        this.$log.error(e);
      }
    },
    
  }
  
};
</script>