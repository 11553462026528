<template>
  <b-row>
    <b-col  sm="5">
      <b-button v-if="!isLoginAs" type="link" variant="primary" class="w-100 mb-2" :to="{ name: 'participant-messages' }">
        <feather-icon icon="MessageSquareIcon" size="16" class="mr-50" />
        Messages
      </b-button>
      <b-card v-if="resources.total">
        <b-row align-v="start" class="d-none d-sm-flex">
          <b-col sm="2" class="pr-0 ">
            <b-avatar square size="50" variant="light-dark">
              <feather-icon icon="BookOpenIcon" size="25" />
            </b-avatar>
          </b-col>
          <b-col sm="9" class="pl-0" >
            <b-card-title class="text-truncate m-0">
              <b-row>
                <b-col >
                  Resources
                  <p class="font-small-3 m-0">
                    Resources available
                  </p>
                </b-col>
                <b-col class="d-flex flex-row-reverse align-items-center">
                  <p class="font-large-1 font-weight-bold d-flex m-0 flex-row-reverse align-items-center">
                    {{ resources.total }}
                  </p>
                </b-col>
              </b-row>
            </b-card-title>
           
            
          </b-col>
        </b-row>

        <b-card-text class="mt-2 text-center">
          <b-button class="w-100" type="link" :to="{ name: 'participant-resources' }" variant="primary">
            View resources
          </b-button>
        </b-card-text>
      </b-card>
    </b-col>
    <b-col sm="7">
      <b-alert variant="dark" class="d-flex p-1 align-items-center" show>
        <b-avatar square size="40" variant="white" class="mr-1">
          <feather-icon icon="FileTextIcon" size="25" class="color-black"/>
        </b-avatar>
        <div >
          <p>Modules will open in a new window. Please make sure pop-ups are enabled to view them.</p>
        </div>
      </b-alert>
      <training-list />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BButton,
  BLink,
  BProgress,
  BAvatar,
  BAlert
} from "bootstrap-vue";
import {
  AOM_GREEN_COLOR
} from "@/libs/utils";
import TrainingList from "../../training/TrainingList.vue";
import KeyDates from "@/views/components/common/KeyDates.vue";
import { makeErrorToast } from "@/libs/utils";
import Box from "@/assets/images/icons/box.svg";
import { loaderTypes, programTypes, userRoles } from '@models';
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { mapGetters } from "vuex"
import trainingsService from "@/services/trainingsService";
import MyAvailibility from '@/participants/mentoring-on-demand/partials/MyAvailibility.vue';
export default {
  name: 'TrainingDashboard',
  components: {
    TrainingList,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    KeyDates,
    BLink,
    AomSkeletonLoader,
    BProgress,
    BAvatar,
    BAlert,
    MyAvailibility
  },
  data() {
    return {
      row: [],
      mySvg: Box,
      trainingUrl: "",
      defaultChartColor: "#FFA500",
      defaultGreenChartColor: AOM_GREEN_COLOR,
      defaultChartOptions: {
        chart: {
          type: 'radialBar',
          renderTo: 'container',
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          offsetY: 0,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
      },
    }
  },
  
  computed: {
    ...mapGetters("participants", ['isApplicationAnswersLoading', 'applicationMentee', 'applicationMentor']),
    ...mapGetters('profile', ['isUserMentee', 'isUserMentor']),
    isLoginAs() {
      const userData = this.profile;
      if (userData?.logged_in_by && Number(userData?.logged_in_by.id) !== Number(userData.id)) {
        return true;
      }
      return false;
    },
    appCurrentProgram() {
      return this.$store.getters['app/currentProgram'];
    },

    program() {
      return this.$store.getters['participants/program'];
    },

    programTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },

    programs() {
      return this.$store.getters['participants/programs'];
    },
    trainings() {
      return this.$store.getters['participants/trainings'];
    },

    resources() {
      return this.$store.getters['participants/resources'];
    },

    participants() {
      return this.$store.getters['participants/participants'];
    },

    messages() {
      return this.$store.getters['participants/messages'];
    },

    profile() {
      return this.$store.getters['profile/profile'];
    },

  },
  methods: {
    onContactChampion() {
      this.$router.push({ name: this.$route.name, query: { isContactProgramManager: 1 } });
    }
  },
  setup() {
    return {
      AOM_GREEN_COLOR,
      loaderTypes
    };
  },
};
</script>

<style lang="scss" scoped>
.h-50px {
  height: 50px;
}

.py-8px {
  padding-top: 8px;
  padding-bottom: 8px;
}

::v-deep .default-chart-color {
  .progress-bar {
    background-color: #FFA500 !important;
  }
}

::v-deep .default-green-chart-color {
  .progress-bar {
    background-color: #9bcc65 !important;
  }
}
.color-black{
  color: #373b3e
}
</style>