<template>
  <div>
    <b-card
      border-variant="primary"
      tag="article"
      class="h-100"
    >
      <b-card-text>
        <b-card-title class="text-truncate mb-1">
          {{ training.course_name }}
        </b-card-title>
        <p class="mb-0">
          <b-badge
            v-for="role in training.roles"
            :key="role"
            variant="primary"
            class="font-weight-normal"
            style="margin-right: 2px;"
          >
            {{ role }}
          </b-badge>
        </p>
      </b-card-text>

      <b-card-text>
        <h6
          v-if="firstAccessDate"
          class="text-base font-weight-medium mb-1"
        >
          Started on <span class="text-body-1">{{ formatDate(firstAccessDate) }}</span>
        </h6>
        <h6
          v-if="completedDate && isCompleted"
          class="text-base font-weight-medium mb-1"
        >
          Completed on <span class="text-body-1">{{ formatDate(completedDate) }}</span>
        </h6>
      </b-card-text>

      <b-dropdown-divider style="list-style-type: none" />

      <b-card-text>
        <div
          class="
            d-flex
            flex-column
            text-sm
            mt-1
            mb-1
          "
        >
          <span v-if="spent">Time spent: {{ spent }}</span>
        </div>

        <b-progress
          :value="Number(isCompleted)"
          :max="max"
          class="mb-2"
        />

        <b-button
          :disabled="isLaunching"
          variant="primary"
          @click="launchProgramTraining(training.id)"
        >
          <b-spinner
            v-if="isLaunching"
            small
          />
          <span v-else>{{ isCompleted ? "Re-launch" : "Launch" }}</span>
        </b-button>

        <b-button
          v-if="!isCompleted"
          :disabled="isUpdating"
          class="ml-1"
          variant="outline-primary"
          @click="markProgramTrainingComplete(training.id)"
        >
          <b-spinner
            v-if="isUpdating"
            small
          />
          <span v-else>I have completed this training</span>
        </b-button>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BAvatar,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCard,
  BSpinner,
  BCardTitle,
  BDropdownDivider,
  BProgress,
  BBadge,
} from "bootstrap-vue";
import trainingsService from "@/services/trainingsService";
import { programTypes, trainingStatus } from "@/models";
import { makeSuccessToast, makeErrorToast, localeDateStringFromIsoDateTime, AOM_GREEN_COLOR } from '@/libs/utils';

export default {
  components: {
    BAvatar,
    BRow,
    BCol,
    BButton,
    BCardText,
    BCard,
    BSpinner,
    BCardTitle,
    BDropdownDivider,
    BProgress,
    BBadge,
  },
  props: {
    training: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: 0,
      max: 1,
      rows: [],
      isLaunching: false,
      isUpdating: false,
      defaultChartColor: AOM_GREEN_COLOR,
      defaultGreenChartColor: AOM_GREEN_COLOR,
      defaultChartOptions: {
        chart: {
          type: 'radialBar',
          renderTo: 'container',
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          offsetY: 0,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 0,
              size: '50%',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
            },
            track: {
              strokeWidth: '95%',
              margin: 0,
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                formatter: function(val) {
                  return parseInt(val) + "%";
                },
                color: '#003366',
                fontSize: '20px',
                lineHight: '1',
                show: true,
                offsetY: 6,
                offsetX:0,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("participants", [
      "program",
      "mentorDisplay",
      "menteeDisplay",
    ]),

    activeParticipantPercentageChartOption() {
      if (this.activeParticipantPercentage[0] > 50) {
        return {...this.defaultChartOptions, colors: [this.defaultGreenChartColor]};
      }

      return {...this.defaultChartOptions, colors: [this.defaultChartColor]};
    },
    activeParticipantPercentage() {
      return [30];
    },
    trainingDetail() {
      if (!this.training || !this.training.program_training_users) {
        return null;
      }
      return this.training.program_training_users[
        this.training.program_training_users.length - 1
      ];
    },

    firstAccessDate() {
      if (!this.trainingDetail || !this.trainingDetail.first_access_date) {
        return null;
      }

      return this.trainingDetail.first_access_date;
    },
    lastAccessDate() {
      if (!this.trainingDetail || !this.trainingDetail.last_access_date) {
        return null;
      }

      return this.trainingDetail.last_access_date;
    },

    completedDate() {
      if (!this.trainingDetail) {
        return null;
      }

      return this.trainingDetail.completed_date;
    },
    isProgramTraining () {
      return this.program?.type_id === programTypes.TRAINING;
    },

    spent() {
      if (!this.trainingDetail) {
        return 'Not started';
      }

      const spentTime = this.trainingDetail.total_seconds_tracked || 0;

      if (spentTime === 0) {
        return '0 minute';
      }

      // Don't show if seconds less than 60
      if (spentTime < 60) {
        return '0 minute';
      }

      return `${Math.round(spentTime / 60)} minutes`;
    },

    isCompleted() {
      if (!this.trainingDetail) {
        return false;
      }
      if (!this.trainingDetail.status) {
        return false;
      }

      return this.trainingDetail.status.id === trainingStatus.COMPLETE;
    },
  },
  methods: {
    async launchProgramTraining(trainingId) {
      this.isLaunching = true;
      try {
        const response = await trainingsService.launchProgramTraining(
          this.program.id,
          trainingId
        );
        if (response && response.data) {
          if (this.isProgramTraining) {
            this.$emit('launchTraining', response.data.launch_link);
          } else {
            window.open(response.data.launch_link);
          }
        }
      } catch (e) {
        const { status, data } = e.response;
        if (status === 404 || status === 400 || status === 500) {
          this.$toast(makeErrorToast(data.message));
        } else {
          this.$toast(makeErrorToast("Something went wrong. Please try again later."));
        }
        this.$log.error(e);
      } finally {
        this.isLaunching = false;
      }
    },

    async markProgramTrainingComplete(trainingId) {
      this.isUpdating = true;
      try {
        const response = await trainingsService.markProgramTrainingsComplete(
          this.program.id,
          {trainings: [{ id: trainingId }]}
        );
        if (response && response.data) {
          this.$toast(makeSuccessToast("Training has been successfully marked as completed."));
          this.$emit('completed');
        }
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Please try again later."));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },

    formatDate(datetime) {
      if (datetime) {
        return localeDateStringFromIsoDateTime(datetime, true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.training-item .card-body {
  display: grid;
  align-items: center;
}

</style>